import { Box, Drawer, useMediaQuery } from '@mui/material';

import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Scrollbar from './Scrollbar';
import MainSidebarSection from './MainSidebarSection';
import Logo from './Logo';
import AuthAclGuard from './AuthAclGuard';

export default function MainSidebar(props) {
  const { onClose, open, sections } = props;
  const router = useRouter();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  const handlePathChange = () => {
    if (!router.isReady) {
      return;
    }

    if (open) {
      onClose?.();
    }
  };

  useEffect(
    handlePathChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady, router.asPath],
  );

  const content = (
    <Scrollbar
      sx={{
        overflowX: 'hidden',
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          sx={{ px: 3, py: 1, height: 64 }}
        >
          <NextLink
            href="/"
            style={{ display: 'block' }}
          >
            <Logo
              sx={{
                height: 38,
                width: 187,
                display: 'block',
              }}
              variant="light"
            />
          </NextLink>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {sections.map((section) => (
            <AuthAclGuard
              key={section.title}
              requiredAcl={section.acl ?? []}
            >
              <MainSidebarSection
                path={router.asPath}
                sx={{
                  mt: 2,
                  '& + &': {
                    mt: 2,
                  },
                }}
                {...section}
              />
            </AuthAclGuard>
          ))}
        </Box>
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => (theme.palette.mode === 'dark' ? 1 : 0),
            color: '#FFFFFF',
            width: 200,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          // backgroundColor: 'neutral.900',
          // color: '#FFFFFF',
          width: 250,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
}

MainSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sections: PropTypes.array,
};

MainSidebar.defaultProps = {
  onClose: null,
  open: false,
  sections: [],
};
