import { Chip } from '@mui/material';

export default function BetaTag({ sx }) {
  return (
    <Chip
      color="warning"
      label="Beta"
      size="small"
      sx={sx}
    />
  );
}
