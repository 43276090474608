import {
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { languages } from '@config';
import LanguagePopover from './LanguagePopover';

export default function LanguageButton(props) {
  const anchorRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <>
      <Tooltip
        title={t('components.LanguageButton.tooltip')}
      >
        <IconButton
          ref={anchorRef}
          onClick={handleOpenPopover}
          sx={{ mr: 1 }}
        >
          <Box
            sx={{
              display: 'flex',
              height: 20,
              width: 20,
              '& img': {
                width: '100%',
              },
            }}
          >
            <img
              alt=""
              src={languages.find((l) => l.lang === i18n.language)?.icon}
            />
          </Box>
        </IconButton>
      </Tooltip>
      <LanguagePopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
}
