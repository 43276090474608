import { gql } from '@apollo/client';
import { useQuery } from '@lib/hooks/index';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export default function useTagItems(tagReference = null) {
  const { i18n } = useTranslation();
  const sort = useMemo(() => ['index', `name_${i18n.language}`], [i18n.language]);
  return useQuery(FETCH_TAGS, {
    variables: {
      sort,
      tagReference,
    },
  });
}

const FETCH_TAGS = gql`
  query Fetch($sort: [String!]!, $tagReference: String!) {
    tagItems: tagItems2(
      sort: $sort,
      filters: {
        referenceId: { eq: $tagReference}
      },
      populate: "simple"
    )
  }`;
