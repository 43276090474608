import {
  Box,
  Button,
  Hidden,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material';
import AuthAclGuard from '@components/AuthAclGuard';
import NextLink from 'next/link';
import { MAIN_MENU_CONTENT_ITEMS } from '@config';
import { useIFrameViewer } from '@components/IFrameViewer';
import {
  useRef,
  useState,
} from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import BetaTag from '@components/BetaTag';

export default function MainNavbarContentLinks() {
  const router = useRouter();
  const { t } = useTranslation();
  const { openIFrameViewer } = useIFrameViewer();
  const { pathname } = router;

  const handleOpenExternalLink = (url) => (e) => {
    if (url.startsWith('http')) {
      e.preventDefault();
      e.stopPropagation();
      openIFrameViewer(url);
    }
  };

  return (
    <>
      <Hidden lgDown>
        {MAIN_MENU_CONTENT_ITEMS.primary.map((item) => (
          <AuthAclGuard key={item.id} requiredAcl={item.acl}>
            <Box mx={0.25}>
              <NextLink
                href={t(item.path)}
                legacyBehavior
                passHref
              >
                <Button
                  component="a"
                  onClick={handleOpenExternalLink(t(item.path))}
                  size="small"
                  startIcon={item.icon}
                  sx={{ width: '100%' }}
                  variant={isSelected(pathname, t(item.path)) ? 'contained' : item.variant}
                >
                  {item.isBeta ? <BetaTag /> : t(item.label)}
                </Button>
              </NextLink>
            </Box>
          </AuthAclGuard>
        ))}
      </Hidden>
      <Hidden lgDown>
        <DotMenuPopover onClick={handleOpenExternalLink} />
      </Hidden>
    </>
  );
}

function DotMenuPopover(props) {
  const { sx, onClick } = props;
  const { t } = useTranslation();
  const rootRef = useRef();
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <Box sx={sx}>
      <IconButton
        ref={rootRef}
        onClick={handleOpenPopover}
        size="small"
        sx={{ width: '100%' }}
      >
        <FiMoreVertical />
      </IconButton>
      <Popover
        anchorEl={rootRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClosePopover}
        open={openPopover}
        slotProps={{
          paper: {
            sx: { width: 280 },
          },
        }}
        transitionDuration={0}
      >
        <List>
          {MAIN_MENU_CONTENT_ITEMS.secondary.map((item) => (
            <NextLink
              key={item.id}
              href={t(item.path)}
              legacyBehavior
              passHref
            >
              <ListItemButton
                component="a"
                onClick={onClick(t(item.path))}
              >
                <ListItemText primary={t(item.label)} />
              </ListItemButton>
            </NextLink>
          ))}
        </List>
      </Popover>
    </Box>
  );
}

function isSelected(path, prefix) {
  return path.startsWith(prefix);
}
