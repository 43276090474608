import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';

export const QUERY_FILTER_PERSIST = {
  default: 'session',
  none: false,
  session: 'session',
  local: 'local',
  url: 'url',
};

export const QUERY_TYPE = {
  string: 'string',
  array: 'array',
};

/**
 *
 * @param {string} key
 * @param {*} initialState
 * @param {(false|true|"session"|"local")} persist
 * @param {("string"|"array")} type
 * @returns {unknown[]}
 */
export function useQueryFilter(
  key,
  initialState,
  persist = QUERY_FILTER_PERSIST.default,
  type = QUERY_TYPE.string,
) {
  const router = useRouter();
  const context = useMemo(() => ({ router }), [router]);
  let value = initialState;
  const storedValue = persist !== QUERY_FILTER_PERSIST.none
    ? getStorage(persist, context).getItem(key)
    : null;

  if (storedValue !== null) {
    try {
      value = JSON.parse(storedValue);

      if (type === QUERY_TYPE.array && !Array.isArray(value)) {
        value = initialState;
      }
    } catch {
      // no-op
    }
  }

  const [queryFilterState, setQueryFilterState] = useState(value);

  const handleSetQueryFilterState = useCallback(
    (event) => {
      const newValue = event?.target ? event.target.value : event;
      setQueryFilterState(newValue);

      if (persist !== QUERY_FILTER_PERSIST.none) {
        getStorage(persist, context).setItem(key, JSON.stringify(newValue));
      }
    },
    [key, persist, context, setQueryFilterState],
  );

  return [queryFilterState, handleSetQueryFilterState];
}

function getStorage(type, { router }) {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line no-param-reassign
    type = QUERY_FILTER_PERSIST.url;
  }

  switch (type) {
    case QUERY_FILTER_PERSIST.local:
      return window.localStorage;
    case QUERY_FILTER_PERSIST.url:
      return new UrlStorage(router);
    default:
      return window.sessionStorage;
  }
}

class UrlStorage {
  constructor(router) {
    this.router = router;
    this.windowLocation = new URL(`http://www.example.com${router.asPath}`);
    this.searchParams = this.windowLocation.searchParams;
  }

  getItem(key) {
    return this.searchParams.get(key);
  }

  setItem(key, value) {
    const parsedValue = JSON.parse(value);
    const filterItems = {};
    const tagItems = [];

    if (typeof parsedValue === 'object') {
      Object.keys(parsedValue).forEach((item) => {
        if (item === 'tags') {
          parsedValue[item].filter((x) => x.tagItemChild)
            .forEach((tag) => tagItems.push(tag.tagItemChild));
          if (tagItems.length) {
            filterItems[item] = tagItems.join(',');
          }
        } else filterItems[item] = parsedValue[item];
      });

      if (Object.keys(filterItems).length) {
        this.searchParams.set(key, JSON.stringify(filterItems));
        this.pushState();
      } else this.deleteItem(key);
      return;
    }

    this.searchParams.set(key, value);
    this.pushState();

    if (!parsedValue) {
      this.deleteItem(key);
    }
  }

  deleteItem(key) {
    this.searchParams.delete(key);
    this.pushState();
  }

  pushState() {
    this.router.push(this.windowLocation, null, { shallow: true });
    // window.history.pushState({}, '', this.windowLocation);
  }
}
